import { FormEvent, useCallback, useState } from 'react';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { spacing } from 'theme';
import { DevOnlyLoginMethod, DevOnlyRoleSelector } from 'components/login/DevOnlyRoleSelector';
import LoginError from 'components/login/LoginError';
import { LoginForm } from 'components/login/LoginForm';
import EppoLogo from 'components/logo/EppoLogo';
import { FlexBox } from 'components/style-components/FlexBox';
import { SEEN_WELCOME_PAGE_STORAGE_KEY } from '../teams/constants';
import { LoginPageLayout } from './styled';
export default function LoginPage() {
  const [loginMethod, setLoginMethod] = useState<DevOnlyLoginMethod>('auth0');
  const router = useRouter();
  const seenWelcomePage = getCookie(SEEN_WELCOME_PAGE_STORAGE_KEY);
  const next = router.query['next'];
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL || '';

  // If path contains ?next= query param, pass it on to Auth0 for post-login redirection.
  const loginUrl = `${baseUrl}/login${next ? `?next=${next}` : ''}`;
  const shouldHandleAuth0Login = process.env.NEXT_PUBLIC_APP_ENV !== 'dev' || loginMethod === 'auth0';
  const handleDevOnlyFormSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/local-dev/dev-login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyId: 1,
        role: loginMethod
      })
    });
    if (res.status === 200) {
      const redirectLink = !seenWelcomePage ? '/welcome' : '/experiments';
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}/${redirectLink}`;
    } else {
      const payload = await res.json();
      const message = payload.errorMessage ?? 'Unknown error';
      alert(message);
    }
  }, [loginMethod, seenWelcomePage]);
  return <LoginPageLayout>
      <EppoLogo color="dark" height="32px" />
      <FlexBox width="50%" height="100%" flexDirection="column" gap={spacing.lg} justifyContent="center" alignItems="center">
        <LoginError />
        <DevOnlyRoleSelector onChange={value => {
        setLoginMethod(value);
      }} loginMethod={loginMethod} />
        {shouldHandleAuth0Login ? <LoginForm submitType="link" loginUrl={loginUrl} /> : <form onSubmit={handleDevOnlyFormSubmit}>
            <LoginForm submitType="form" />
          </form>}
      </FlexBox>
    </LoginPageLayout>;
}