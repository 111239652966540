import styled from '@emotion/styled';
import Link from 'next/link';

/**
 * Primitive UI component that resets default browser link styles.
 *
 * Useful when you need to wrap something in a link and don't want to deal with browser defaults.
 *
 * @example
 *
 * ```tsx
 * // Making a card clickable and act as a link.
 * <UnstyledLink href="...">
 *   <Card>
 *     ...
 *     Card contents
 *     ...
 *   </Card>
 * </UnstyledLink>
 *
 * // Creating a customized link component.
 * const CustomLink = styled(UnstyledLink)({
 *   // Your styles here.
 * });
 * ```
 */
export const UnstyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit'
  }
});