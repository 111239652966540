import { useRouter } from 'next/router';
import { LoginWrapper } from 'components/login/styled';
import { FlexBox } from 'components/style-components/FlexBox';
import InlineLink from 'components/ui/link/InlineLink';
import Text from 'components/ui/typography/Text';
import { spacing } from 'theme/spacing';
const ERRORS = {
  ACCOUNT_DISABLED: {
    title: 'This account is disabled.',
    message: <>Please contact your Administrators for assistance.</>
  },
  UNAUTHORIZED_DOMAIN: {
    title: "We couldn't find that account.",
    message: <>
        Looking for a demo? Sign up to{' '}
        <InlineLink href="https://www.geteppo.com/tour-the-app">try Eppo now.</InlineLink>
      </>
  },
  BLOCKED_BY_SCIM: {
    title: 'We could not log you in because there was an issue with single sign-on. ',
    message: <>Please contact your Administrators for assistance.</>
  },
  BLOCKED_BY_STRICT_WORKSPACE: {
    title: 'We could not log you in because there was an issue with single sign-on. ',
    message: <>Please contact your Administrators for assistance.</>
  },
  INTERNAL: {
    title: 'We could not log you in because there was an authentication error.',
    message: null
  }
};
type ErrorType = keyof typeof ERRORS;
export default function LoginError() {
  const router = useRouter();
  const error = ERRORS[(router.query.error as ErrorType)];
  if (!error) {
    return null;
  }
  return <LoginWrapper>
      <FlexBox flexDirection="column" gap={spacing.sm}>
        <Text bold>{error.title}</Text>
        {error.message && <Text>{error.message}</Text>}
      </FlexBox>
    </LoginWrapper>;
}