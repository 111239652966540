import { CSSProperties, ReactNode } from 'react';
import { COLOR_GREY_200 } from 'constants/colors';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import { fontSize, lineHeight } from 'theme';
import { colors } from 'theme/colors';
export const textVariants = (['body', 'body-large', 'body-small', 'body-extra-small', 'mono', 'label', 'tag', 'pill'] as const);
export type TextVariant = typeof textVariants[number];
export const isTextVariant = (str: unknown): str is TextVariant => textVariants.includes((str as TextVariant));
interface ITextBase {
  variant?: TextVariant;
  bold?: boolean;
  italic?: boolean;
  hover?: CSSProperties;
  ellipsis?: boolean;

  // overrides
  color?: string;
  whiteSpace?: Property.WhiteSpace;
  textTransform?: Property.TextTransform;
  fontFamily?: string;
  textAlign?: Property.TextAlign;
  margin?: string;
  padding?: string;
  backgroundColor?: string;
}
const TextBase = styled.p<ITextBase>({
  lineHeight: lineHeight.text
}, ({
  variant = 'body'
}) => ({
  body: {
    fontSize: fontSize.body
  },
  'body-large': {
    fontSize: fontSize.bodyLarge
  },
  'body-small': {
    fontSize: fontSize.bodySmall
  },
  'body-extra-small': {
    fontSize: fontSize.bodyExtraSmall
  },
  mono: {
    fontFamily: '"Cofo Sans Mono", monospace !important',
    fontSize: fontSize.bodySmall
  },
  label: {
    fontSize: fontSize.bodySmall,
    textTransform: ('capitalize' as Property.TextTransform)
  },
  pill: {
    border: `1px solid ${COLOR_GREY_200}`,
    borderRadius: 6,
    padding: '4px 8px',
    fontSize: fontSize.bodyExtraSmall,
    textTransform: ('uppercase' as Property.TextTransform)
  },
  tag: {
    fontSize: fontSize.bodyExtraSmall,
    textTransform: ('uppercase' as Property.TextTransform)
  }
})[variant],
// Overrides
({
  color = colors.text.primary,
  fontFamily = 'Cofo Sans, sans-serif',
  textAlign,
  bold = false,
  italic = false,
  whiteSpace,
  textTransform,
  margin = '0',
  padding,
  ellipsis,
  backgroundColor
}) => ({
  color,
  whiteSpace,
  fontFamily,
  textAlign,
  fontWeight: bold ? '500' : '400',
  fontStyle: italic ? 'italic' : 'normal',
  textTransform,
  margin,
  padding,
  backgroundColor,
  ...(ellipsis ? {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  } : {})
}), ({
  hover
}) => ({
  '&:hover': {
    ...hover
  }
}));
interface IText extends ITextBase {
  children: ReactNode;
  inline?: boolean;
  className?: string;
  cyElementType?: string;
  style?: CSSProperties;
}
export default function Text({
  inline,
  children,
  ...props
}: IText) {
  return <TextBase as={inline ? 'span' : 'p'} {...props}>
      {children}
    </TextBase>;
}