import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { colors } from 'theme';
import { isUrlAbsolute } from 'helpers/link';
import { UnstyledLink } from './UnstyledLink';
const InlineLinkBase = styled(UnstyledLink)({
  color: colors.text.link,
  fontWeight: 500,
  '&:hover': {
    color: colors.hover.primary,
    cursor: 'pointer'
  }
});
type NextLinkProps = PropsOf<typeof Link>;
interface IInlineLink extends NextLinkProps {
  href: string;
}

/**
 * Primitive UI component for rendering inline links in text blocks.
 *
 * @example
 *
 * ```tsx
 * <Text>
 *   You can complete this step after the feature flag is created. You can also add or edit
 *   allocations to this flag later by visiting the{' '}
 *   <InlineLink href={FEATURE_FLAG_LINK}>Feature Flags page</InlineLink>.
 * </Text>
 * ```
 */
export default function InlineLink({
  href,
  ...props
}: IInlineLink) {
  const hrefIsAbsoluteURL = isUrlAbsolute(href);
  return <InlineLinkBase href={href} {...hrefIsAbsoluteURL ? {
    target: '_blank',
    rel: 'noreferrer'
  } : {}} {...props} />;
}