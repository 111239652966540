export function getCookie(cookieKey: string): string {
  if (!cookieKey) return '';
  const cookie = document.cookie;
  let token = '';
  (cookie || '')
    .split(';')
    .map((element) => element.trim())
    .forEach((element) => {
      const [key, value] = element.split('=');
      if (key == cookieKey) token = value;
    });
  return token;
}

export function resetCookie() {
  document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
