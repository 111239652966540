import { ReactNode } from 'react';
import { useController, FieldValues, FieldPath, Control, FieldPathValue } from 'react-hook-form';
import { colors, spacing } from 'theme';
import { FlexBox } from 'components/style-components/FlexBox';
import Icon from 'components/ui/icon/Icon';
import { Box } from 'components/ui/layout/Box';
import Tooltip from 'components/ui/tooltip/Tooltip';
import Label from 'components/ui/typography/Label';
import Text from 'components/ui/typography/Text';
type IFieldChildrenProps<TFormValues extends FieldValues, TName extends FieldPath<TFormValues>> = {
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: FieldPathValue<TFormValues, TName>;
  name: TName;
  disabled?: boolean;
  state?: 'error';
};
interface IField<TFormValues extends FieldValues, TName extends FieldPath<TFormValues>> {
  name: TName;
  control?: Control<TFormValues>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  infoText?: ReactNode | string;
  helperText?: ReactNode | string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...events: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (event?: any) => void;
  children: (field: IFieldChildrenProps<TFormValues, TName>) => ReactNode;
}

/**
 * Shortcut component for building Form- components. Should work for most default form components.
 *
 * NOTE: For custom Form- component layout, just copy-paste this component into yours, and change it as you wish.
 *
 * @example
 *
 * ```tsx
 * function FormTextInput<TFormValues extends FieldValues>({
 *   name,
 *   control,
 *   label,
 *   // ...and other Field props
 *
 *   // Input props.
 *   onChange,
 *   onBlur,
 *   ...props
 * }: IFormTextInput<TFormValues>): JSX.Element {
 *   return (
 *     <Field
 *       name={name}
 *       control={control}
 *       label={label}
 *       // ...and other Field props
 *       onChange={onChange}
 *       onBlur={onBlur}
 *     >
 *       {(field) => <TextInput {...props} {...field} />}
 *     </Field>
 *   );
 * }
 * ```
 *
 * ```tsx
 * // ...or you can use it inline.
 * <Field name="subjectKey" control={form.control}>
 *   {(field) => (
 *     <FlexBox alignItems="center" gap={spacing.sm}>
 *       <Text bold>Subject key</Text>
 *       <FlexBoxItem flex={1} />
 *       <Text>=</Text>
 *       <TextInput {...field} css={{ width: '192px' }} />
 *     </FlexBox>
 *   )}
 * </Field>
 * ```
 */
export default function Field<TFormValues extends FieldValues, TName extends FieldPath<TFormValues>>({
  name,
  control,
  label,
  disabled,
  required,
  infoText,
  helperText,
  onChange,
  onBlur,
  children
}: IField<TFormValues, TName>) {
  const {
    field,
    fieldState
  } = useController({
    name,
    control,
    // These props will propagate to the input. Passing them here to avoid conflicts between rhf and props.
    rules: {
      onChange,
      onBlur
    }
  });
  const hasError = fieldState.isTouched && Boolean(fieldState.error);
  const forwardedField: IFieldChildrenProps<TFormValues, TName> = {
    name: field.name,
    value: field.value,
    onChange: field.onChange,
    onBlur: field.onBlur,
    disabled: disabled,
    state: hasError ? 'error' : undefined
  };
  return <FlexBox flexDirection="column">
      {label && <FieldLabel htmlFor={name} required={required} infoText={infoText}>
          {label}
        </FieldLabel>}

      {children(forwardedField)}

      <Box css={{
      paddingTop: spacing.xs
    }}>
        {hasError ? <FieldErrorText>{fieldState.error?.message}</FieldErrorText> : <>
            {typeof helperText === 'string' ? <FieldHelperText>{helperText}</FieldHelperText> : helperText}
          </>}
      </Box>
    </FlexBox>;
}
interface IFieldLabel {
  required?: boolean;
  infoText?: ReactNode | string;
  htmlFor?: string;
  children: string | undefined;
}

/**
 * Component for rendering form field label.
 *
 * Also renders a star icon if the field is `required`, and an info icon with `labelInfoText` tooltip if the text is provided.
 */
export const FieldLabel = ({
  required,
  infoText,
  htmlFor,
  children
}: IFieldLabel) => {
  if (!children) return null;
  return <FlexBox alignItems="center" height={spacing.xl}>
      {required && <Icon name="Asterisk" color={colors.text.error} size={12} css={{
      marginRight: spacing.xs
    }} />}
      <Label htmlFor={htmlFor}>{children}</Label>
      {infoText && <Tooltip text={infoText}>
          <Icon name="Info.Unfilled small" color={colors.text.secondary} />
        </Tooltip>}
    </FlexBox>;
};

/**
 * Component for rendering field error text.
 */
export const FieldErrorText = ({
  children,
  className
}: {
  children: string | undefined;
  className?: string;
}) => {
  if (!children) return null;
  return <Text variant="body-small" color={colors.text.error} className={className}>
      {children}
    </Text>;
};

/**
 * Component for rendering field helper text.
 */
export const FieldHelperText = ({
  children,
  className
}: {
  children: string | undefined;
  className?: string;
}) => {
  if (!children) return null;
  return <Text variant="body-small" color={colors.text.secondary} className={className}>
      {children}
    </Text>;
};