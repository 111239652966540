import styled from '@emotion/styled';
import { borderRadius, spacing } from 'theme';

import { colors } from 'theme/colors';

import BGImage from './img/login.jpg';

const LOGIN_WIDTH = 368;

export const LoginPageLayout = styled.div({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: `url(${BGImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  padding: '32px 32px 64px',
  '@media (max-width: 500px)': {
    background: 'none',
  },
});

export const LoginPageColumn = styled.div({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.lg,
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoginWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
  width: LOGIN_WIDTH,
  padding: spacing.lg,
  borderRadius: borderRadius.md,
  border: `1px solid ${colors.border.primary}`,
  backgroundColor: colors.surface.white,
});
