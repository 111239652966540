import React from 'react';
import { COLOR_WHITE } from 'constants/colors';
import styled from '@emotion/styled';
import Link from 'next/link';
import { colors, spacing } from 'theme';
import { LoginWrapper } from 'components/login/styled';
import { FlexBox } from 'components/style-components/FlexBox';
import InlineLink from 'components/ui/link/InlineLink';
import Heading from 'components/ui/typography/Heading';
import Text from 'components/ui/typography/Text';
type Auth0LoginProps = {
  submitType: 'link';
  loginUrl: string;
};
type DevRoleLoginProps = {
  submitType: 'form';
};
export const LoginForm: React.FunctionComponent<DevRoleLoginProps | Auth0LoginProps> = props => {
  const termsUrl = `https://app.termly.io/document/terms-of-use-for-saas/4c635cc8-24f6-4c05-83fa-0382fca756ce`;
  const privacyPolicyUrl = `https://app.termly.io/document/privacy-policy/a555478b-524f-4b53-b70e-6575d94ad3c7`;
  return <LoginWrapper>
      <FlexBox flexDirection="column" gap={spacing.xl}>
        <Heading variant="h3">Log in or Sign up</Heading>

        {props.submitType === 'link' && <Link href={props.loginUrl} prefetch={false}>
            <LoginButton>Continue to Eppo</LoginButton>
          </Link>}
        {props.submitType === 'form' && <LoginButton type="submit">Continue to Eppo</LoginButton>}
        <Text color={colors.text.secondary}>
          By continuing, you agree to Eppo&apos;s{' '}
          <InlineLink href={termsUrl} target="_blank">
            Terms of Use
          </InlineLink>
          . Read our{' '}
          <InlineLink href={privacyPolicyUrl} target="_blank">
            Privacy Policy.
          </InlineLink>
        </Text>
      </FlexBox>
    </LoginWrapper>;
};
const LoginButton = styled.button({
  width: '336px',
  height: '48px',
  fontSize: '13px',
  color: COLOR_WHITE,
  backgroundColor: '#6C55D4',
  // matches the logo. refactor after M1 launch
  borderRadius: '8px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 12px'
});