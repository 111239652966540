// Front-end flag keys
// Note: Back end registry is at feature-switch-registry.ts in the API package.

export enum EFeatureFlag {
  PlanningCalculator = 'planning-calculator',
  ExperimentDetailExtendedPrecisionKey = 'experiment-detail-extended-precision',
  UseUtcKey = 'use-utc',
  FeatureFlagAuditLog = 'feature-flag-audit-log',
  ExperimentMetricDisplayAbsoluteLift = 'experiment-metric-display-absolute-lift',
  ManualRefresh = 'manual-refresh',
  BayesianDetailsLossForControl = 'loss-for-control-in-bayesian-details',
  DefinitionsSkipRun = 'definitions-skip-run',
  UnlimitedEnvironments = 'unlimited-environments',
  FeatureFlagNotification = 'feature-flag-notification',
  HoldoutsAnalysisOnly = 'holdouts-analysis-only',
  HoldoutsEndToEnd = 'holdouts-end-to-end',
  KillAllQueriesButton = 'kill-all-queries-button',
  UserTeams = 'user-teams',
  TeamExperimentPermissions = 'team-experiment-permissions-1',
  TeamMetricPermissions = 'team-metric-permissions',
  MetricSync = 'metric-sync',
  WorkflowSettingsPage = 'workflow-settings-page',
  DisableNotifications = 'disable-notifications',
  EmailNotifications = 'email-notifications',
  FeatureFlagApproval = 'feature-flag-approval',
  CountDistinctAgg = 'count-distinct-agg',
  ExperimentRealTimeMetricsUi = 'experiment-live-metrics-ui',
  ExperimentRealTimeMetricsManualRecompute = 'experiment-live-metrics-manual-recompute',
  ExperimentPropertyAnalysis = 'property-analysis-tab',
  FeatureFlagSemVer = 'feature-flag-semver-support',
  ShowTeamsPickerInterstitial = 'show-teams-picker-interstitial',
  FeatureFlagTeamPermissions = 'feature-flag-team-permissions',
  KnowledgeBase = 'knowledge-base',
  Layers = 'layers',
  InviteUser = 'invite-user',
  MultipleExperimentAllocation = 'multiple-experiment-allocation',
  BanditsUI = 'bandits-ui',
  SourceDiagnostics = 'source-diagnostics',
  SearchBarV2 = 'search-bar-v2',
  BigQueryOAuth = 'big-query-oauth',
  BigQueryIdentityPool = 'big-query-identity-pool',
  FFAudiences = 'ff-audiences',
  AdminMetricExportLog = 'admin-metric-export-log',
  PipelineMetricFilterDimensions = 'pipeline-metric-filter-dimensions',
  DatePartitionKeyUX = 'date-partition-key-ux',
  WebhooksAdminTab = 'webhooks-admin-tab',
  PipelineVisibilityWarehouseLeftNav = 'pipeline-visibility-warehouse-left-nav',
  FactLaggingTable = 'fact-lagging-table',
  MetricWindows = 'metric-windows',
  TeamScorecards = 'team-scorecards',
  TeamScorecardsQualityCard = 'team-scorecards-quality-card',
  ClusteredAnalysis = 'clustered-analysis',
  FeatureFlagTrials = 'feature-flag-trials',
  SecondaryId = 'secondary-id',
  SecondaryIdMetricCollections = 'secondary-id-metric-collections',
  FFDetailsPageEnvironmentsSidebar = 'ff-details-page-environments-sidebar',
  NonInferiorityMetrics = 'non-inferiority-metrics',
  FeatureFlagScheduledRollouts = 'feature-flag-scheduled-rollouts',
  LastValueAggregation = 'last-value-aggregation',
  NoKnowledgeBaseExplores = 'no-knowledge-base-explores',
  AnalysisWindowDatesOnly = 'analysis-window-dates-only',
  AssignmentRename = 'assignment-rename',
  NewAnalysisCreateFlow = 'new-analysis-create-flow',
  PercentileMetrics = 'percentile-metrics',
  FFDependentFlags = 'dependent-flags',
  IncrementalEffectView = 'incremental-effect-view',
  ExperimentMetricViewDefault = 'experiment-metric-view-default',
  MetricWinsorFixedValue = 'metric-winsor-fixed-value',
  OptInMetricProperties = 'opt-in-metric-properties',
  FlagOverrides = 'flag-overrides',
  OptInMetricPropertiesDisableFactLevelConfig = 'opt-in-metric-properties-disable-fact-level-config',

  // flagging
  FeatureFlagTags = 'feature-flag-tags',
  ProtocolsMilestoneOne = 'protocols-milestone-1',
  WaterfallPhaseOne = 'ff-ux-assignment-waterfall-phase-1',
  FeatureFlagMonitoringUI = 'feature-flag-monitoring-ui',
  WaterfallPhaseTwo = 'ff-ux-assignment-waterfall-phase-2',
  LiveSubjectTesting = 'feature-flag-live-subject-testing',
  NavigationImprovements = 'navigation-improvements',
  FlaggingEnvironmentAutomatedValidation = 'flagging-environment-automated-validation',
  SelectiveHoldouts = 'selective-holdouts',

  QuasiExperimentsM0 = 'quasi-experiments-m0',
  QuasiExperimentTestDesign = 'quasi-experiment-test-design',
  RunningOverviewCard = 'running-overview-card',
  DisableMetricPropertyExplores = 'disable-metric-property-explores',

  RecommendedDecision = 'recommended-decision',
  PredefinedTargetingAttributes = 'predefined-attributes',
  ExperimentTimelineView = 'experiment-timeline-view',
  ExploreChartMaxDimensions = 'explore-chart-max-dimensions',
  ExploreChartDaysSinceAssignment = 'explore-chart-days-since-assignment',

  SwitchbackAnalysis = 'switchback-testing-ui',

  EnforceSCIM = 'enforce-scim',
  SdkEventLogging = 'sdk-event-logging',

  MultivariateComparisonView = 'multivariate-comparison-view',
  ConversionFromPriorStepFirst = 'conversion-from-prior-step-first',
  DuplicateMetricsWhenAddingMEE = 'duplicate-metrics-when-adding-mee',

  NewFFCreationFlow = 'new-ff-creation-flow',
  PipelineNoFullRefreshOnSqlChange = 'pipeline-no-full-refresh-on-sql-change',
  WarehouseVisibilityUI = 'warehouse-visibility-ui',
  ExperimentTags = 'experiment-tags',
  InteractionEffectDetection = 'interaction-effect-detection',
}
